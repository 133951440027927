<template>
  <footer class="grid place-items-center gap-6 border-t border-gray-500 bg-white px-5 pt-12">
    <article>
      <HpIcon name="healthpilot-icon" class="mx-auto mb-1.5 w-10" />
      <h2 class="text-center text-3xl">We're Here to Help with Any Questions</h2>
      <SitePhoneCsHours :is-footer="true" class="my-4" />
    </article>

    <div class="flex space-x-8">
      <HpIcon name="bbb-footer-logo" class="w-20 text-[#B9B9B9]" />
      <HpIcon name="trustpilot-footer-logo" class="w-28" />
    </div>

    <p
      class="footer-disclaimer max-w-3xl text-base leading-relaxed text-primary-900"
      id="footer-pre"
    ></p>

    <p class="max-w-3xl text-base leading-relaxed text-primary-900" v-html="formattedDisclaimer" />

    <div class="text-base" id="filing-code">
      <p v-if="!!(SiteFilingCode.supplementaryFilingCode.value.length > 0)">
        {{ SiteFilingCode.supplementaryFilingCode.value }}
      </p>
      <p>{{ SiteFilingCode.filingCode.value }}</p>
    </div>

    <div class="rounded-2xl border border-border px-4 py-2 text-sm" v-if="isQaOrDevelopment">
      Journey: {{ session?.flags['journey-id'] }}
      <br />
      Profile Id: {{ session.profileId }}
    </div>

    <nav
      aria-label="Footer"
      class="flex w-full max-w-3xl flex-col flex-wrap items-center justify-center gap-x-14 gap-y-2 border-y border-border py-2 sm:flex-row"
    >
      <HpAnchor
        :href="content.ext.href_privacy"
        target="_blank"
        class="font-bold text-primary-900 no-underline"
      >
        Privacy Policy
      </HpAnchor>
      <HpAnchor
        :href="content.ext.href_terms"
        target="_blank"
        class="font-bold text-primary-900 no-underline"
      >
        Terms of Use
      </HpAnchor>
      <HpAnchor
        href="#"
        v-dialog-target:site-disclosures-modal
        class="whitespace-nowrap font-bold text-primary-900 no-underline"
      >
        Additional disclaimers
      </HpAnchor>
    </nav>

    <client-only>
      <AuviousLauncher class="mb-32" />
    </client-only>
    <SiteDisclosuresModal id="site-disclosures-modal" />
  </footer>
</template>

<script lang="ts" setup>
  import { PlanType } from '~/generated/api-clients-generated'
  import { SessionStore } from '~/stores/session'

  const { getComponentContent } = Cms.use()
  const { content } = await getComponentContent('SiteFooter')

  const session = SessionStore.use()

  const validPlanTypes = [PlanType.MAPD, PlanType.PDP]

  const noOfCarriers = computed(
    () => _uniq(session.carrierFootprint.map((x) => x.carrierKey)).length
  )

  const noOfPlans = computed(() =>
    session.carrierFootprint.reduce(
      (acc, n) => acc + n.planTypes?.reduce((acc0, n0) => acc0 + n0!.count!, 0)!,
      0
    )
  )

  // if we don't have footprint yet, don't show "disclaimer_counts" sentence
  const formattedDisclaimer = computed(() =>
    content.value?.ext?.disclaimer?.replace(
      '{{disclaimer_counts}}',
      session.carrierFootprint.length
        ? content.value?.ext?.disclaimer_counts
            ?.replace('{{carrier_count}}', noOfCarriers.value?.toString())
            .replace('{{plan_count}}', noOfPlans.value?.toString())
        : ' '
    )
  )

  nextTick(() => {
    if (!SiteFilingCode.filingCode.value.length) SiteFilingCode.setFilingCode()
  })

  const { isQaOrDevelopment } = DevModeHelpers.use()
</script>

<style scoped>
  :deep(a) {
    @apply text-base;
  }
</style>
