<template>
  <p class="text-center text-base leading-normal">
    Currently getting help from one of our world class support associates?
    <span class="mt-2 block">
      <HpAnchor id="cobrowse-start" @click="launch()" class="!font-semibold">Click here</HpAnchor>
      so they can see your screen.
    </span>
  </p>
</template>

<script lang="ts" setup>
  const { $root } = useNuxtApp()

  const emit = defineEmits(['launch-auvious'])

  function launch() {
    $root.emit('launch-auvious')
    emit('launch-auvious')
  }
</script>

<style scoped></style>
