<template>
  <HpAnchor
    :href="`tel:${phoneNumber}`"
    class="block whitespace-nowrap text-center text-gray-900 no-underline"
    :class="{ 'text-white': monochrome }"
  >
    <span v-if="!hidden" class="block text-lg leading-snug">
      <span class="mr-1 font-semibold text-primary" :class="{ 'text-white': monochrome }">
        {{ phoneNumber }}
      </span>
      | TTY 711
    </span>
    <span
      v-if="isAep && !hidden"
      class="whitespace-nowrap text-xs text-gray-800 lg:text-sm"
      :class="{ 'text-white': monochrome }"
    >
      {{ hours }}
    </span>
    <span
      v-if="!isAep && !hidden"
      class="text-sm text-gray-800"
      :class="{ 'text-white': monochrome }"
    >
      {{ hours }}
    </span>
  </HpAnchor>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { PlanType } from '~/generated/api-clients-generated'
  import { SiteStore } from '~/stores/site'
  import DisplaySupportHours from '~/composables/DisplaySupportHours'

  const props = defineProps<{
    monochrome: {
      type: Boolean
    }
    isFooter: {
      type: Boolean
    }
  }>()

  const { getComponentContent } = Cms.use()

  const contactCardContent = await getComponentContent('HelperCardPageCompare')

  const { hours } = await DisplaySupportHours.use()

  const session = SessionStore.use()
  const site = SiteStore.use()
  const { path } = AppRoute.use()
  const { flag } = FeatureFlags.use()
  const isAep = computed(() => flag<boolean>('is-aep').value)
  const hidden = computed(() => {
    if (site.journey.id != 4 || props.isFooter) {
      return false
    }
    if (_isNil(path.value)) {
      return true
    }
    if (
      path.value === '/zip' ||
      path.value.includes('/county') ||
      path.value === '/plan-year' ||
      path.value === '/plan-type' ||
      path.value === '/'
    ) {
      return true
    }
    return (
      (path.value === '/drugs' || path.value === '/medicaid') && session.planType === PlanType.PDP
    )
  })

  const phoneNumber = computed(() => {
    switch (true) {
      case path.value.includes('/apply'):
        return '(855) 208-8559'
      case path.value === '/quotes' ||
        path.value.includes('/details') ||
        path.value.includes('/quotes') ||
        path.value.includes('/compare'):
        switch (true) {
          case session.planType === PlanType.PDP:
            return contactCardContent.content.value.ext.pdp_tfn
          case session.planType === PlanType.MAPD:
            return contactCardContent.content.value.ext.mapd_tfn
          default:
            return contactCardContent.content.value.ext.phone_number
        }
      default:
        return contactCardContent.content.value.ext.phone_number
    }
  })
</script>

<style scoped></style>
