<template>
  <section
    id="menu"
    :class="!isMenuOpen ? '-translate-x-full' : 'translate-x-0'"
    class="fixed inset-0 z-50 flex w-72 flex-col space-y-8 overflow-hidden rounded-r-sidebar bg-blue-500 p-5 pt-12 transition-transform md:hidden"
  >
    <h2 class="break-words text-3xl font-bold text-white">
      {{ content.ext.greeting }} {{ profile?.firstName }}!
    </h2>

    <nav class="h-full" :class="{ hidden: !isMenuOpen }" aria-label="Main">
      <SiteNavItem
        v-if="isSignedIn"
        name="Dashboard"
        icon="dashboard"
        to="/dashboard"
        @click="$emit('close-menu')"
      />

      <SiteNavItem
        v-else
        name="Save your progress"
        icon="touch"
        to="#"
        @click.prevent="signUpRedirect()"
        @click="$emit('close-menu')"
      />

      <SiteNavItem
        v-if="isSignedIn"
        name="Sign out"
        icon="logout"
        to="#"
        @click.prevent="signOut"
        @click="$emit('close-menu')"
      />

      <SiteNavItem
        v-else
        name="Sign in"
        icon="login"
        to="#"
        @click.prevent="signInRedirect()"
        @click="$emit('close-menu')"
      />
    </nav>

    <article class="z-10 grid place-items-center gap-3 pb-7 text-white">
      <h3 class="text-white">Need Help?</h3>
      <HpButton variant="secondary" @click="showChat('talkdesk')">
        <HpIcon name="help" />
        Talk to an agent
      </HpButton>
      <p>or call us at:</p>
      <SitePhoneCsHours monochrome stack-hours />
    </article>

    <HpIcon
      name="healthpilot-logo-icon"
      class="absolute -bottom-10 -right-12 w-80 text-white opacity-5"
    />
  </section>
</template>

<script lang="ts" setup>
  import { ProfileStore } from '~/stores/profile'

  defineProps({
    isMenuOpen: {
      type: Boolean,
      required: true
    }
  })

  const { show: showChat } = ChatController.use()

  const { getComponentContent } = Cms.use()

  const { content } = await getComponentContent('SiteNav')

  const { isAuthenticated, signOut, signUpRedirect, signInRedirect } = Auth.use()
  const isSignedIn = ref(isAuthenticated.value)
  watch(isAuthenticated, () => (isSignedIn.value = isAuthenticated.value))

  const profile = ProfileStore.use()
</script>

<style scoped>
  :deep(strong) {
    @apply font-semibold text-white;
  }
</style>
