<template>
  <header class="sticky top-0 z-40 bg-white px-5 py-2" @keydown.esc="closeDropdown">
    <nav class="navigation">
      <HpButton
        class="mobile-back-btn block w-4 md:hidden"
        :class="{ invisible: isStartPage }"
        variant="minimal"
        icon="back"
        icon-classes="w-8 text-primary-600"
        icon-location="left"
        @click="back"
      >
        <span class="sr-only">Go back</span>
      </HpButton>

      <HpAnchor @click.prevent="() => maybeDo(() => push('/'))" class="mx-auto w-fit md:mx-0">
        <LogoSvg class="hidden sm:block sm:w-[11.5rem]" />
        <HpIcon name="healthpilot-icon" class="relative w-9 sm:hidden" />
      </HpAnchor>

      <SitePhoneCsHours :is-footer="false" class="hidden md:block" />

      <div class="relative">
        <HpButton
          :variant="isSignedIn ? 'secondary' : 'primary'"
          class="ml-auto block w-fit !px-2.5 !py-2 sm:!px-5 md:min-w-52"
          :class="isSignedIn ? 'hidden md:block' : 'block'"
          @click.prevent="headerCTA.action"
          :compact="isMobile"
        >
          <HpIcon
            v-if="headerCTA.icon === 'account'"
            name="account"
            class="mr-1.5"
            :class="{ 'hidden xs:block': hideIconMobile }"
          />

          <HpIcon
            v-if="headerCTA.icon === 'touch'"
            name="touch"
            class="mr-1.5"
            :class="{ 'hidden xs:block': hideIconMobile }"
          />
          <span class="whitespace-nowrap text-base !font-semibold sm:text-lg">
            {{ headerCTA.label }}
          </span>
        </HpButton>

        <HpButton
          v-if="isSignedIn"
          variant="minimal"
          :aria-expanded="isMenuOpen"
          class="z-50 ml-auto flex !min-h-0 w-fit touch-manipulation md:hidden"
          :class="!isMenuOpen"
          @click="toggleMenu"
          @keydown.esc="closeMenu"
        >
          <HpIcon name="account" class="w-9 text-primary-600" />
          <span class="sr-only">Menu</span>
        </HpButton>

        <ul
          ref="desktopDropdownMenu"
          class="absolute right-3.5 top-full hidden w-44 origin-top scale-y-0 overflow-hidden rounded-b-tooltip bg-white shadow-menu transition-transform md:block"
          :class="{ expand: isDropdownOpen, 'invisible hidden': !isDropdownOpen }"
        >
          <li class="border-b border-border px-4 pb-2.5 pt-3.5 hover:bg-gray-200">
            <NuxtLink
              to="/dashboard"
              @click="closeDropdown"
              class="flex items-center gap-1 text-base font-semibold text-primary-900 no-underline"
            >
              <HpIcon name="dashboard" class="w-5" />
              Dashboard
            </NuxtLink>
          </li>
          <li class="px-4 py-1 hover:bg-gray-200">
            <HpButton
              variant="minimal"
              class="flex w-fit items-center !text-base !font-semibold !text-primary-900"
              @click.prevent="signOut"
              @click="closeDropdown"
            >
              <HpIcon name="logout" class="w-5" />
              Sign Out
            </HpButton>
          </li>
        </ul>
      </div>
    </nav>
  </header>

  <!-- Mobile Menu -->
  <client-only>
    <SiteNavSlideOutMenu :is-menu-open="isMenuOpen" @close-menu="closeMenu" />
  </client-only>

  <!-- Page backdrop for open mobile menu -->
  <div
    v-if="isMenuOpen"
    @click="closeMenu"
    class="fixed inset-0 z-40 grid place-content-center md:hidden"
  ></div>
</template>

<script lang="ts" setup>
  import LogoSvg from '~/assets/images/healthpilot.svg?component'
  import { onClickOutside } from '@vueuse/core'

  const { isAuthenticated, signOut, signUpRedirect, signInRedirect } = Auth.use()
  const isSignedIn = ref(isAuthenticated.value)
  watch(isAuthenticated, (val) => (isSignedIn.value = val))
  const { isDesktop } = Breakpoints.use()
  const { isMobile } = Breakpoints.use()

  const { isStartPage } = AppRoute.use()

  const { back, push } = AppRouter.use()

  const { maybeDo } = ActionGuard.use()

  const headerCTA = computed(() => {
    switch (true) {
      case isSignedIn.value:
        return {
          label: 'My Account',
          icon: 'account',
          action: () => toggleDropdown()
        }
      case isStartPage.value && !isSignedIn.value:
        return {
          label: 'Sign in',
          icon: 'account',
          action: () => signInRedirect()
        }
      default:
        return {
          label: !isDesktop.value ? 'Save progress' : 'Save your progress',
          icon: 'touch',
          action: () => maybeDo(() => signUpRedirect())
        }
    }
  })

  const hideIconMobile = computed(() => headerCTA.value.label !== 'Sign in')

  const isMenuOpen = ref(false)

  const toggleMenu = () => (isMenuOpen.value = !isMenuOpen.value)

  const closeMenu = () => (isMenuOpen.value = false)

  const isDropdownOpen = ref(false)

  const toggleDropdown = () => (isDropdownOpen.value = !isDropdownOpen.value)

  const closeDropdown = () => (isDropdownOpen.value = false)

  watch(isMenuOpen, () => {
    isMenuOpen.value
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  })

  const desktopDropdownMenu = ref(null)

  onClickOutside(desktopDropdownMenu, () => closeDropdown())
</script>

<style scoped>
  /* NOTE: Additional invisible class on desktopDropdownMenu element prevents links in the dropdown menu from being tabbable when menu is not expanded  */
  .navigation {
    @apply mx-auto grid w-full max-w-[--pageWidth] items-center;
    grid-template-columns: 2fr 1fr 2fr;
  }

  .expand {
    @apply scale-y-100;
  }
</style>
